
import React, { useState, useEffect } from "react";
import './style.css'
import { setPageState } from "../../Store/RootStore";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';


export default function RenderBottom({ handlePPClick }) {

    const ppclick = (event) => {
        handlePPClick();
        setPageState('5');
        // 将页面滚动到顶部
        window.scrollTo(0, 0);
    };

    const email = 'support@ciscic.org.uk'
    const now = new Date();
    const currentYear = now.getFullYear();
    return (
        <div className="ViewBottomfooter" >
            <div className="footerSection">
                <img className="footerIcon" src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/contact/email.png" alt="Image" />
                <div className="footerdivText"><div className="footerText">Email Address: <a className="email" href={`mailto:${email}`}>{email}</a></div></div>
            </div>
            <div className="footerSection">
                <img className="footerIcon" src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/contact/address.png" alt="Image" />
                <div className="footerText">Address: 8 Northumberland Avenue, London, WC2N 5BY, United Kingdom</div>
            </div>
            <div className="footerSection">
                <div className="footerText2 foothover" onClick={ppclick}>Privacy Policy</div>
                <div className="seperate"> | </div>
                <div className="footerText2">
                    Copyright © 2019 - {currentYear} Chinese International Students Well-being Support Centre CIC (Company no.12249552) - All Rights Reserved.
                </div>
            </div>

        </div>
    );

}