import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { APIPost } from "../../../API/APIMethod";
import { Box, TextField, Typography, MenuItem } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Utils from '../../../Utils/Utils'

import "./styles.css";


const theme = createTheme({
  palette: {
    primary: {
      main: '#D0D3D5', // 设置主要颜色
    },
    secondary: {
      main: '#D05EE1', // 设置次要颜色
    },
    error: {
      main: '#D05EE1', // 设置次要颜色
    },
    // 更多颜色定义...
  },
});



function WXCISCTestHistoryPage() {
  const [resultList, setResultList] = useState([]);
  const [errorEmail, setErrorEmail] = useState(false)
  const [name, setName] = useState('');

  
  let navigate = useNavigate();

  useEffect(() => {
    console.log('componentDidMount');

    // 设置字体
    Utils.initWXCISCPageStyle();

    if (global.wxOpenId) {
      requestData({ openid: global.wxOpenId });
    }
  }, []);

  const requestData = (params) => {
    const path = '/getciscwxusertestanswerlistbyopenid/';
    const onSuccess = (res) => {
      const data = res?.data;
      console.log('data', data);
      if (data.ciscanswerinfolist.length >= 0) {
        setResultList(data.ciscanswerinfolist?.reverse());
      }
    };
    APIPost(path, params).then(onSuccess);
  };

  const handleClick = (item) => {
    const queryParams = new URLSearchParams();
    queryParams.append('id', item.id);

    const path = {
      pathname: '/ciscqatestreport',
      search: queryParams.toString()
    };

    console.log('reportxxx', path);
    return path;
  };

  const handleClickLinkToTestPage = () => {
    const queryParams = new URLSearchParams();
    const path = {
      pathname: '/ciscqatest',
      search: queryParams.toString()
    };
    console.log('reportxxx', path);
    return path;
  };

  const handleGotoTestPage = () => {
    navigate('/ciscqatest');
  };

  const handleGotoMain = () => {
    navigate('/assessment');
  }

  const convertTime = (originalDateTimeString) => {
    const date = new Date(originalDateTimeString);
    const formattedDateTime = `${date.getFullYear()}年${(date.getMonth() + 1).toString().padStart(2, '0')}月${date.getDate().toString().padStart(2, '0')}日 ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;

    return formattedDateTime;
  }

  return (
    <div className="viewCISCHistoryPage">
      <div className="bktop">
        <img className="imageHeader" src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/phychotest/history/history.png"/>
        <div className="viewTop foothover">
          <div className="left" onClick={handleGotoMain}>
            <img className="imageBack" src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/phychotest/history/back.png"></img>
          </div>
          <div className="title">历史记录</div>
          <div className="right"></div>
        </div>
      </div>
      <div className="viewlist">
        {/* {[1,2,3,4,5,6,7,8,9].map((item) => {
          return resultList.map((item) => (
            <div className="viewItem">
              <Link to={handleClick(item)}>
                <img className="imageList" src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/phychotest/history/listitem.png" />
                <div className="textResult">{'心检时间：' + convertTime(item.createtime)}</div>
              </Link>
            </div>
          ))
        })} */}
        {resultList.length == 0 && (<div className="textNodata">暂无历史记录</div>)}
        {resultList.map((item) => (
          <div className="viewItem">
            <Link to={handleClick(item)}>
              <img className="imageList" src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/phychotest/history/listitem.png" />
              <div className="textResult">{'心检时间：' + convertTime(item.createtime)}</div>
            </Link>
          </div>
        ))}
      </div>
      <div className="viewfooter">
        <img className="imagefooter" src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/phychotest/footer.png" />
      </div>
      
    </div>
  );
}

export default WXCISCTestHistoryPage;