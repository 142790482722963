import React, { useEffect, useRef, useState } from "react";
import "./styles.css";
import { APIPost } from "../../API/APIMethod";
import { useNavigate } from "react-router-dom";
import { clearTimers } from "mobx-react-lite";

let typeCScore = 0;
let typeSScore = 0;
let typePScore = 0;
let typeMScore = 0;

const WX4TestQAPage = () => {

  const scrollContainerRef = useRef(null);

  const [currentQAIndex, setCurrentQAIndex] = useState(0);
  const [AnswerList, setAnswerList] = useState([]);
  const Separator = '、';

  // const [typeCScore, setTypeCScore] = useState(0);
  // const [typeSScore, setTypeSScore] = useState(0);
  // const [typePScore, setTypePScore] = useState(0);
  // const [typeMScore, setTypeMScore] = useState(0);

  // const typeCScoreRef = useRef(0);
  // const typeSScoreRef = useRef(0);
  // const typePScoreRef = useRef(0);
  // const typeMScoreRef = useRef(0);

  // let typeCScore = typeCScoreRef.current;
  // let typeSScore = typeSScoreRef.current;
  // let typePScore = typePScoreRef.current;
  // let typeMScore = typeMScoreRef.current;

  // let typeCScore = 0;
  // let typeSScore = 0;
  // let typePScore = 0;
  // let typeMScore = 0;


  const [showNotice, setShowNotice] = useState(false);
  const [showReport, setShowReport] = useState(false);

  let QAListInit = [
    {
      No: 1,
      isMulti: 0,
      Q: '人们都说我非常友好',
      A: [
        '符合',
        '不符合',
      ]
    },
    {
      No: 2,
      isMulti: 0,
      Q: '我只有几个朋友，但我们非常亲密',
      A: [
        '符合',
        '不符合',
      ]
    },
    {
      No: 3,
      isMulti: 0,
      Q: '我是天生的领导者',
      A: [
        '符合',
        '不符合',
      ]
    },
    {
      No: 4,
      isMulti: 0,
      Q: '我宁可节省而不肯乱花钱',
      A: [
        '符合',
        '不符合',
      ]
    },
    {
      No: 5,
      isMulti: 0,
      Q: '我宁可节我很会享受生活',
      A: [
        '符合',
        '不符合',
      ]
    },
    {
      No: 6,
      isMulti: 0,
      Q: '我喜欢每一个细节都完美',
      A: [
        '符合',
        '不符合',
      ]
    },
    {
      No: 7,
      isMulti: 0,
      Q: '我不太会让情绪表露出来',
      A: [
        '符合',
        '不符合',
      ]
    },
    {
      No: 8,
      isMulti: 0,
      Q: '人们说我不太靠谱',
      A: [
        '符合',
        '不符合',
      ]
    },
    {
      No: 9,
      isMulti: 0,
      Q: '我容易生气',
      A: [
        '符合',
        '不符合',
      ]
    },
    {
      No: 10,
      isMulti: 0,
      Q: '我难以做出决定',
      A: [
        '符合',
        '不符合',
      ]
    },
    {
      No: 11,
      isMulti: 0,
      Q: '很少有事情能让我生气与不安',
      A: [
        '符合',
        '不符合',
      ]
    },
    {
      No: 12,
      isMulti: 0,
      Q: '和一群人在一起时我喜欢讲生动的故事',
      A: [
        '符合',
        '不符合',
      ]
    },
    {
      No: 13,
      isMulti: 0,
      Q: '我很容易批评事情或指出他人的错误',
      A: [
        '符合',
        '不符合',
      ]
    },
    {
      No: 14,
      isMulti: 0,
      Q: '我是个很自律的人',
      A: [
        '符合',
        '不符合',
      ]
    },
    {
      No: 15,
      isMulti: 0,
      Q: '大家都说我有些冷漠无情',
      A: [
        '符合',
        '不符合',
      ]
    },
    {
      No: 16,
      isMulti: 0,
      Q: '我是个果断的人',
      A: [
        '符合',
        '不符合',
      ]
    },
    {
      No: 17,
      isMulti: 0,
      Q: '我容易感到疲惫',
      A: [
        '符合',
        '不符合',
      ]
    },
    {
      No: 18,
      isMulti: 0,
      Q: '我喜欢闲逛和无所事事',
      A: [
        '符合',
        '不符合',
      ]
    },
    {
      No: 19,
      isMulti: 0,
      Q: '我不是很有组织性',
      A: [
        '符合',
        '不符合',
      ]
    },
    {
      No: 20,
      isMulti: 0,
      Q: '我更喜欢旁观而不参与',
      A: [
        '符合',
        '不符合',
      ]
    },
    {
      No: 21,
      isMulti: 0,
      Q: '我发现自己难以宽恕别人',
      A: [
        '符合',
        '不符合',
      ]
    },
    {
      No: 22,
      isMulti: 0,
      Q: '我在短时间能做许多事',
      A: [
        '符合',
        '不符合',
      ]
    },
    {
      No: 23,
      isMulti: 0,
      Q: '人们说我风趣幽默',
      A: [
        '符合',
        '不符合',
      ]
    },
    {
      No: 24,
      isMulti: 0,
      Q: '我易于忧郁和悲观',
      A: [
        '符合',
        '不符合',
      ]
    },
    {
      No: 25,
      isMulti: 0,
      Q: '我做什么不是太有动机',
      A: [
        '符合',
        '不符合',
      ]
    },
    {
      No: 26,
      isMulti: 0,
      Q: '我非常有耐心',
      A: [
        '符合',
        '不符合',
      ]
    },
    {
      No: 27,
      isMulti: 0,
      Q: '我是个爱说话的人',
      A: [
        '符合',
        '不符合',
      ]
    },
    {
      No: 28,
      isMulti: 0,
      Q: '我实在不喜欢大的聚会，更愿意跟几个亲密好友在一起',
      A: [
        '符合',
        '不符合',
      ]
    },
    {
      No: 29,
      isMulti: 0,
      Q: '我是个热情的人',
      A: [
        '符合',
        '不符合',
      ]
    },
    {
      No: 30,
      isMulti: 0,
      Q: '人们说我是一个非常勇敢的冒险者',
      A: [
        '符合',
        '不符合',
      ]
    },
    {
      No: 31,
      isMulti: 0,
      Q: '我对事情都有清楚的看法',
      A: [
        '符合',
        '不符合',
      ]
    },
    {
      No: 32,
      isMulti: 0,
      Q: '我喜欢休息和睡觉',
      A: [
        '符合',
        '不符合',
      ]
    },
    {
      No: 33,
      isMulti: 0,
      Q: '我喜欢掌控局势与事态',
      A: [
        '符合',
        '不符合',
      ]
    },
    {
      No: 34,
      isMulti: 0,
      Q: '我不擅长交朋友',
      A: [
        '符合',
        '不符合',
      ]
    },
    {
      No: 35,
      isMulti: 0,
      Q: '我喜欢艺术，它能反映我的心灵追求',
      A: [
        '符合',
        '不符合',
      ]
    },
    {
      No: 36,
      isMulti: 0,
      Q: '我爱几乎每一个人',
      A: [
        '符合',
        '不符合',
      ]
    },
    {
      No: 37,
      isMulti: 0,
      Q: '我非常自信',
      A: [
        '符合',
        '不符合',
      ]
    },
    {
      No: 38,
      isMulti: 0,
      Q: '我认为人们不太喜欢我',
      A: [
        '符合',
        '不符合',
      ]
    },
    {
      No: 39,
      isMulti: 0,
      Q: '我平时花钱很大方',
      A: [
        '符合',
        '不符合',
      ]
    },
    {
      No: 40,
      isMulti: 0,
      Q: '我喜欢平平淡淡的生活',
      A: [
        '符合',
        '不符合',
      ]
    },
  ];

  const [QAList, setQAList] = useState(QAListInit);

  useEffect(() => {
    // 回调函数
    const callback = () => {
      console.log('状态更新完成！新的 count 值为: ' + QAList);
    };

    // 每当 count 发生变化时执行回调函数
    callback();
  }, [QAList]); // 仅在 count 变化时执行

  // let depressionScore = 0;
  // let anxietyScore = 0;
  // let pressureScore = 0;
  // let pressureFrom = '';
  // let pressureReleaseWay = '';
  // let schoolSatisfaction = '';
  // let specialitySatisfaction = '';
  // let overallSatisfaction = '';


  console.log('global.wxOpenId four qa', global.wxOpenId);

  let navigate = useNavigate();

  const handleSelect = (text) => {
    let tempList = [...AnswerList];
    const QA = QAList[currentQAIndex];

    if (QA.isMulti === 0) {
      tempList[currentQAIndex] = new Set();
      tempList[currentQAIndex].add(text);
    } else {
      if (tempList[currentQAIndex]) {
        if (tempList[currentQAIndex].has(text)) {
          tempList[currentQAIndex].delete(text);
        } else {
          tempList[currentQAIndex].add(text);
        }
      } else {
        tempList[currentQAIndex] = new Set();
        tempList[currentQAIndex].add(text);
      }
    }

    setAnswerList(tempList);
  };

  const formatNumber = (count) => {
    if (count < 10) {
      return '0' + count;
    }
    return count;
  };

  const getNowTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = formatNumber(now.getMonth() + 1);
    const date = formatNumber(now.getDate());
    const hours = formatNumber(now.getHours());
    const minutes = formatNumber(now.getMinutes());
    const seconds = formatNumber(now.getSeconds());

    console.log(`当前时间为：${year}-${month}-${date} ${hours}:${minutes}:${seconds}`);
    return `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`;
  };

  const checkIfNext = () => {
    return !!AnswerList[currentQAIndex];
  };

  const handleSubmit = () => {
    // Add your submit logic here
    console.log('Answerlist', AnswerList);
    console.log('currentQAIndex', currentQAIndex);

    let fourType = 'typeS';
    if (typeSScore > typeMScore && typeSScore > typeCScore && typeSScore > typePScore) {
      fourType = 'typeS';
    } else if (typeCScore > typeMScore && typeCScore > typeSScore && typeCScore > typePScore) {
      fourType = 'typeC';
    } else if (typeMScore > typeCScore && typeMScore > typeSScore && typeMScore > typePScore) {
      fourType = 'typeM';
    } else if (typePScore > typeCScore && typePScore > typeSScore && typePScore > typeMScore) {
      fourType = 'typeP';
    }

    //second first
    const scores = {
      typeCScore,
      typeSScore,
      typeMScore,
      typePScore
    };

    let maxScore = Number.MIN_SAFE_INTEGER;
    let secondMaxScore = Number.MIN_SAFE_INTEGER;
    let maxScoreName = '';
    let secondMaxScoreName = '';

    for (const key in scores) {
      if (scores[key] > maxScore) {
        secondMaxScore = maxScore;
        secondMaxScoreName = maxScoreName;
        maxScore = scores[key];
        maxScoreName = key;
      } else if (scores[key] > secondMaxScore) {
        secondMaxScore = scores[key];
        secondMaxScoreName = key;
      }
    }

    console.log(`最大值变量名：${maxScoreName}`);
    console.log(`第二大值变量名：${secondMaxScoreName}`);
    // second end

    let fourSecondType = 'typeS';
    if (secondMaxScoreName === 'typeSScore') {
      fourSecondType = 'typeS';
    } else if (secondMaxScoreName === 'typeCScore') {
      fourSecondType = 'typeC';
    } else if (secondMaxScoreName === 'typeMScore') {
      fourSecondType = 'typeM';
    } else if (secondMaxScoreName === 'typePScore') {
      fourSecondType = 'typeP';
    }

    const params = {
      wxOpenId: global.wxOpenId,
      typeSScore: typeSScore,
      typeMScore: typeMScore,
      typeCScore: typeCScore,
      typePScore: typePScore,
      fourType: fourType,
      fourSecondType: fourSecondType,
      createtime: getNowTime(),
    }
    requestSubmit(params);
  };

  const handleBacktoMinePage = () => {
    // window.location.href = 'http://192.168.1.16:3000/assessment';
    // history.push('/assessment');
    // navigate('/assessment');
    navigate(-1);
  };

  const requestSubmit = (params) => {
    const path = '/setciscusertypefourtestanswer/';
    const onSuccess = (res) => {
      const data = res?.data;
      console.log('data', data);
      setShowReport(true);
      // handleBacktoMinePage();
    };

    APIPost(path, params).then(onSuccess);
  };


  //滑动到最上面
  const scrollToTop = () => {
    if (scrollContainerRef.current) {
      console.log('current');
      // scrollContainerRef.current.scrollTo({
      //   top: 0,
      //   behavior: 'smooth'
      // });
      scrollContainerRef.current.scrollTop = 0;
    }
  };


  const handleNext = () => {

    if (!checkIfNext()) {
      return;
    }

    let QAListTemp = QAListInit;

    //S
    if (currentQAIndex === 0 || currentQAIndex === 4 || currentQAIndex === 7 || currentQAIndex === 11 || currentQAIndex === 18 || currentQAIndex === 22 || currentQAIndex === 26 || currentQAIndex === 28 || currentQAIndex === 35 || currentQAIndex === 38) {
      if (AnswerList[currentQAIndex].has('符合')) {
        // setTypeSScore(typeSScore + 1)
        typeSScore = typeSScore + 1;
        console.log('typeSScore', typeSScore);
      }
    }
    //M
    if (currentQAIndex === 1 || currentQAIndex === 5 || currentQAIndex === 6 || currentQAIndex === 12 || currentQAIndex === 13 || currentQAIndex === 23 || currentQAIndex === 27 || currentQAIndex === 33 || currentQAIndex === 34 || currentQAIndex === 37) {
      if (AnswerList[currentQAIndex].has('符合')) {
        // setTypeMScore(typeMScore + 1)
        typeMScore = typeMScore + 1;

        console.log('typeMScore', typeMScore);
      }
    }
    //C
    if (currentQAIndex === 2 || currentQAIndex === 8 || currentQAIndex === 14 || currentQAIndex === 15 || currentQAIndex === 20 || currentQAIndex === 21 || currentQAIndex === 29 || currentQAIndex === 30 || currentQAIndex === 32 || currentQAIndex === 36) {
      if (AnswerList[currentQAIndex].has('符合')) {
        // setTypeCScore(typeCScore + 1)
        typeCScore = typeCScore + 1;
        console.log('typeCScore', typeCScore);
      }
    }
    //P
    if (currentQAIndex === 3 || currentQAIndex === 9 || currentQAIndex === 10 || currentQAIndex === 16 || currentQAIndex === 17 || currentQAIndex === 19 || currentQAIndex === 24 || currentQAIndex === 25 || currentQAIndex === 31 || currentQAIndex === 39) {
      if (AnswerList[currentQAIndex].has('符合')) {
        console.log('beforetypePScore', typePScore);
        // setTypePScore(typePScore + 1)
        typePScore = typePScore + 1;

        console.log('typePScore', typePScore);
      }
    }
    // console.log('currqaindex', currentQAIndex);

    if (currentQAIndex === 40) {
      if (AnswerList[currentQAIndex].has('用高效率和执行力解决问题')) {
        // setTypeCScore(typeCScore + 1)
        typeCScore = typeCScore + 1;

        console.log('40typeCScore', typeCScore);
      } else if (AnswerList[currentQAIndex].has('用道理和逻辑分析说明问题')) {
        // setTypeMScore(typeMScore + 1)
        typeMScore = typeMScore + 1;

        console.log('40typeMScore', typeMScore);
      } else if (AnswerList[currentQAIndex].has('用沟通和友好交流改善关系')) {
        // setTypeSScore(typeSScore + 1)
        typeSScore = typeSScore + 1;

        console.log('40typeSScore', typeSScore);
      } else if (AnswerList[currentQAIndex].has('用耐心和包容陪伴身边的人')) {
        // setTypePScore(typePScore + 1)
        typePScore = typePScore + 1;

        console.log('40typePScore', typePScore);
      }
    }

    if (currentQAIndex === 39) {
      console.log('typePScore', typePScore);
      console.log('typeCScore', typeCScore);
      console.log('typeSScore', typeSScore);
      console.log('typeMScore', typeMScore);

      // setTimeout(() => {
      console.log('timetypePScore', typePScore);
      console.log('typeCScore', typeCScore);
      console.log('typeSScore', typeSScore);
      console.log('typeMScore', typeMScore);

      if (typePScore === typeCScore || typePScore === typeSScore || typePScore === typeMScore ||
        typeCScore === typeSScore || typeCScore === typeMScore ||
        typeSScore === typeMScore
      ) {
        if (typePScore === typeCScore && typePScore === typeSScore && typePScore === typeMScore) {
          QAListTemp.push({
            No: 41,
            isMulti: 0,
            Q: '通常来说 ，你会更擅长：',
            A: [
              '用高效率和执行力解决问题',
              '用道理和逻辑分析说明问题',
              '用沟通和友好交流改善关系',
              '用耐心和包容陪伴身边的人',
            ]
          })
        } else if (typePScore === typeCScore && typePScore === typeSScore) {
          QAListTemp.push({
            No: 41,
            isMulti: 0,
            Q: '通常来说 ，你会更擅长：',
            A: [
              '用高效率和执行力解决问题',
              '用沟通和友好交流改善关系',
              '用耐心和包容陪伴身边的人',
            ]
          })
        } else if (typePScore === typeCScore && typePScore === typeMScore) {
          QAListTemp.push({
            No: 41,
            isMulti: 0,
            Q: '通常来说 ，你会更擅长：',
            A: [
              '用高效率和执行力解决问题',
              '用道理和逻辑分析说明问题',
              '用耐心和包容陪伴身边的人',
            ]
          })
        } else if (typePScore === typeSScore && typePScore === typeMScore) {
          QAListTemp.push({
            No: 41,
            isMulti: 0,
            Q: '通常来说 ，你会更擅长：',
            A: [
              '用道理和逻辑分析说明问题',
              '用沟通和友好交流改善关系',
              '用耐心和包容陪伴身边的人',
            ]
          })
        } else if (typeCScore === typeSScore && typeCScore === typeMScore) {
          QAListTemp.push({
            No: 41,
            isMulti: 0,
            Q: '通常来说 ，你会更擅长：',
            A: [
              '用高效率和执行力解决问题',
              '用道理和逻辑分析说明问题',
              '用沟通和友好交流改善关系',
            ]
          })
        }
        // two
        else if (typePScore === typeCScore) {
          QAListTemp.push({
            No: 41,
            isMulti: 0,
            Q: '通常来说 ，你会更擅长：',
            A: [
              '用高效率和执行力解决问题',
              '用耐心和包容陪伴身边的人',
            ]
          })
        } else if (typePScore === typeSScore) {
          QAListTemp.push({
            No: 41,
            isMulti: 0,
            Q: '通常来说 ，你会更擅长：',
            A: [
              '用沟通和友好交流改善关系',
              '用耐心和包容陪伴身边的人',
            ]
          })
        } else if (typePScore === typeMScore) {
          QAListTemp.push({
            No: 41,
            isMulti: 0,
            Q: '通常来说 ，你会更擅长：',
            A: [
              '用道理和逻辑分析说明问题',
              '用耐心和包容陪伴身边的人',
            ]
          })
        } else if (typeCScore === typeSScore) {
          QAListTemp.push({
            No: 41,
            isMulti: 0,
            Q: '通常来说 ，你会更擅长：',
            A: [
              '用高效率和执行力解决问题',
              '用沟通和友好交流改善关系',
            ]
          })
        } else if (typeCScore === typeMScore) {
          QAListTemp.push({
            No: 41,
            isMulti: 0,
            Q: '通常来说 ，你会更擅长：',
            A: [
              '用高效率和执行力解决问题',
              '用道理和逻辑分析说明问题',
            ]
          })
        } else if (typeSScore === typeMScore) {
          QAListTemp.push({
            No: 41,
            isMulti: 0,
            Q: '通常来说 ，你会更擅长：',
            A: [
              '用道理和逻辑分析说明问题',
              '用沟通和友好交流改善关系',
            ]
          })
        }

        //需要加41题的时候走这里
        console.log('add QAListTemp', QAListTemp);
        setQAList(QAListTemp);

        setCurrentQAIndex(currentQAIndex + 1);

        // clearTimeout(timer39);

      } else {
        // 不需要41题，正常提交
        if (currentQAIndex === QAList.length - 1) {
          console.log('提交数据，返回上一页，跳转到报告');
          handleSubmit();
        }
      }
      // clearTimeout(timer39);
      // }, 1000);

    } else {
      // != 39
      if (currentQAIndex === QAList.length - 1) {
        console.log('提交数据，返回上一页，跳转到报告');
        handleSubmit();
        return;
      }

      setCurrentQAIndex(currentQAIndex + 1);
    }

    const timer2 = setTimeout(() => {
      //滑动到最上面
      scrollToTop();
      clearTimeout(timer2);
    }, 10);

  };

  const renderXuanxiang = (text) => {
    let isSelected = false;
    const currentAnswer = AnswerList[currentQAIndex];

    if (currentAnswer?.has?.(text)) {
      isSelected = true;
    }

    const viewAnswerClassName = isSelected ? 'viewAnswerSelect' : 'viewAnswer';
    const textAnswerClassName = isSelected ? 'textAnswerSelect' : 'textAnswer';

    return (
      <div className={viewAnswerClassName} onClick={() => handleSelect(text)}>
        <div className={textAnswerClassName}>{text}</div>
      </div>
    );
  };

  const renderXuanxiangType1 = (text) => {
    let isSelected = false;
    const currentAnswer = AnswerList[currentQAIndex];

    if (currentAnswer?.has?.(text)) {
      isSelected = true;
    }

    if (text === '符合') {
      const viewAnswerClassName = isSelected ? 'viewAnswerSelectType1Right' : 'viewAnswerType1Right';

      return (
        <div className={viewAnswerClassName} onClick={() => handleSelect(text)}>
        </div>
      );
    } else {
      const viewAnswerClassName = isSelected ? 'viewAnswerSelectType1Wrong' : 'viewAnswerType1Wrong';

      return (
        <div className={viewAnswerClassName} onClick={() => handleSelect(text)}>
        </div>
      );
    }

  };


  const currentQA = QAList[currentQAIndex];

  const handleGotoMain = () => {
    navigate('/assessmentfourtest');
  }

  if (showNotice) {
    return (
      <div className="viewNoticeFourTest">
        <div className="viewimagenotice">
          <img className="imagenotice" src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/phychotest/qa/notice.png" />
        </div>
        <div className="viewImageStart" onClick={() => setShowNotice(false)}>
          <img className="imagestart" src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/phychotest/qa/start.png" />
        </div>
        <div className="viewBack" onClick={handleGotoMain}>
          <img className="imageBack" src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/phychotest/history/back.png"></img>
        </div>
      </div>
    )
  }

  if (showReport) {
    return (
      <div className="viewNoticeFourTest">
        <div className="viewimagenotice">
          <img className="imagenotice" src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/fourtypetest/qasuccess.png" />
        </div>
        <div className="viewImageStart" onClick={() => navigate('/fourtesthistory')}>
          <img className="imagestart" src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/fourtypetest/qatoreport.png" />
        </div>
        <div className="viewBack" onClick={handleGotoMain}>
          <img className="imageBack" src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/phychotest/history/back.png"></img>
        </div>
      </div>
    )
  }


  // const textno = (currentQAIndex + 1) + '/' + QAList.length + '题';
  const nopercent = ((currentQAIndex + 1) * 100 / QAList.length).toFixed(2) + '%';
  const textno = '第 ' + (currentQAIndex + 1) + ' 题';


  const textQuestion = currentQAIndex < 40 ? '请您根据直觉的第一反应快速回答下面的题目，符合你的实际情况的画“✓”，不符合的画“×”：' : '以下为单选题，只能选择一项相对来说最符合你的。\r\n通常来说 ，你会更擅长：'

  return (
    <div className="viewFourTestCISCQAPage">
      <div className="viewTop">
        <div className="viewimageTop">
          <img className="viewimageTop" src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/fourtypetest/qatopbk.png" />
        </div>
        <div className="viewTopIn">
          <div className="viewBack" onClick={handleGotoMain}>
            <img className="imageBack" src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/phychotest/history/back.png"></img>
          </div>
          <div className="textNO">{textno}</div>
          {/* <div className="viewSliderOut">
            <div className="viewSliderIn" style={{ width: nopercent }}></div>
          </div> */}
        </div>
      </div>
      {/* <span className="viewQuestion">
        <span className="textMulti">{currentQA.isMulti == 1 ? '多选题' : '单选题'}</span><span className="textQuestion">{currentQA.Q}</span>
      </span> */}
      <span className="viewQuestion">
        <span className="textQuestion">{textQuestion}</span>
      </span>


      <div className="viewContent" ref={scrollContainerRef} >
        {currentQAIndex < 40 && (
          <>
            <div className="viewContentNormalQuestion">
              <div className="textQ">{currentQA.Q}</div>
            </div>
            <div className="viewQAsType1" >
            {renderXuanxiangType1('不符合')}
            {renderXuanxiangType1('符合')}
            </div>
          </>
        )}
        {currentQAIndex >= 40 && (
          <div className="viewQAs" >
            {currentQA.A.map((item) => renderXuanxiang(item))}
          </div>
        )}

        <div className={checkIfNext() ? "nextButton" : "nextButtonGray"} onClick={handleNext}>
          {/* {currentQAIndex === QAList.length - 1 ? '提交' : '下一题'} */}
          {currentQAIndex === 40 ? '提交' : '下一题'}
        </div>
      </div>
    </div>
  );
};

export default WX4TestQAPage;