import React, { useEffect, useState } from 'react';
import './App.css';
// import './Fonts/fonts.css'
import RenderFirstPageOut from './RenderFirstPageOut'
import MobileIndex from './Mobile/Pages/MobileIndex';
import WXContactUs from './WXContactUs'
import WXCISCTestQAPage from './WXCISCTest/Pages/WXCISCTestQAPage';
import WXCISCTestReportPage from './WXCISCTest/Pages/WXCISCTestReportPage';
import WXCISCTestMinePage from './WXCISCTest/Pages/WXCISCTestMinePage';
import WXCISCTestHistoryPage from './WXCISCTest/Pages/WXCISCTestHistoryPage';
import WX4TestQAPage from './WX4Test/WX4TestQAPage';
import WX4TestMinePage from './WX4Test/WX4TestMinePage';
import WX4TestHistoryPage from './WX4Test/WX4TestHistoryPage';
import WX4TestReportPage from './WX4Test/WX4TestReportPage';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import RootStore from './Store/RootStore'
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { observer } from "mobx-react-lite"

const App = observer(() => {

  let fontStyles;

  // 根据操作系统选择字体样式文件
  if (navigator.userAgent.toLowerCase().includes('micromessenger') !== -1) {
    fontStyles = require('./Fonts/fontsmobile.css');
  } else if (navigator.userAgent.indexOf('Windows') !== -1) {
    fontStyles = require('./Fonts/fonts.css');
  } else if (navigator.userAgent.indexOf('Mac') !== -1) {
    fontStyles = require('./Fonts/fontsmobile.css');
  } else if (navigator.userAgent.indexOf('Linux') !== -1) {
    fontStyles = require('./Fonts/fonts.css');
  } else if (navigator.userAgent.match(/(iPad|iPhone|iPod)/g)) {
    fontStyles = require('./Fonts/fontsmobile.css');
  } else if (navigator.userAgent.match(/Android/g)) {
    fontStyles = require('./Fonts/fontsmobile.css');
  } else {
    fontStyles = require('./Fonts/fonts.css');
  }


  const [isMobile, setIsMobile] = useState(document.documentElement.clientWidth <= 750);
  const [isWXCISCMobile, setIsWXCISCMobile] = useState(document.documentElement.clientWidth <= 450);


  RootStore.clientWidth = document.documentElement.clientWidth;
  RootStore.clientHeight = document.documentElement.clientHeight;

  let doc = document;
  let win = window;
  let docEl = doc.documentElement,
    resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',

    recalc = function () {
      let clientWidth = docEl.clientWidth;
      let clientHeight = docEl.clientHeight;


      if (!clientWidth) return;
      if (clientWidth > 750) {
        setIsMobile(false);
      } else {
        setIsMobile(true);
      }

      if (clientWidth > 0 && clientHeight / clientWidth > 1.65) {
        setIsWXCISCMobile(true);
      } else {
        setIsWXCISCMobile(false);
      }

      ///AppWXCISCPC
      // const container = document.querySelector('.AppWXCISCPC');
      // const windowHeight = window.innerHeight;
      // container.style.height = `${windowHeight}px`;
      // container.style.width = `${windowHeight / 2.15}px`;

      // alert(docEl.style.fontSize)
    };

  if (!doc.addEventListener) return;
  win.addEventListener(resizeEvt, recalc, false);
  doc.addEventListener('DOMContentLoaded', recalc, false);

  // return (
  //   <div className="App">
  //     {!isMobile ? <RenderFirstPageOut pageState={RootStore.pageState}/> : <MobileIndex pageState={RootStore.pageState}/>}
  //   </div>
  // );


  const mainpage =
    <div className="App">
      {!isMobile ? <RenderFirstPageOut pageState={RootStore.pageState} /> : <MobileIndex pageState={RootStore.pageState} />}
    </div>
    ;

  const wxcontact = <div className="App"><WXContactUs /></div>

  // const cisctestQA = <div className="App"><WXCISCTestQAPage /></div>
  const cisctestQA = <div className={isWXCISCMobile ? "App" : "AppWXCISCPCCaintainer"}>
    <div className={isWXCISCMobile ? "AppWXCISX" : "AppWXCISCPC"}><WXCISCTestQAPage /></div>
  </div>


  let cisctestReport = <div className="App"><WXCISCTestReportPage /></div>;
  if (isWXCISCMobile) {
    cisctestReport = <div className="App"><WXCISCTestReportPage /></div>
  } else {
    cisctestReport = <div className="AppWXCISXReportCaintainer">
      <div className="AppWXCISCPCReport"><WXCISCTestReportPage /></div>
    </div>
  }
  // const cisctestReport = <div className="App"><WXCISCTestReportPage /></div>
  // const cisctestReport = <div className={isWXCISCMobile ? "App" : "AppWXCISCPCCaintainer"}>
  //   <div className={isWXCISCMobile ? "AppWXCISX" : "AppWXCISCPC"}><WXCISCTestReportPage /></div>
  // </div>

  // const cisctestmine = <div className='App'><WXCISCTestMinePage /></div>
  const cisctestmine = <div className={isWXCISCMobile ? "App" : "AppWXCISCPCCaintainer"}>
    <div className={isWXCISCMobile ? "AppWXCISX" : "AppWXCISCPC"}><WXCISCTestMinePage /></div>
  </div>

  // const cisctesthistory = <div className="App"><WXCISCTestHistoryPage /></div>
  const cisctesthistory = <div className='AppWXCISCPCCaintainer'>
    <div className={isWXCISCMobile ? "AppWXCISX" : "AppWXCISCPC"}><WXCISCTestHistoryPage /></div>
  </div>

  // const wx4testQA = <div className="App"><WX4TestQAPage /></div>
  const wx4testQA = <div className='AppWXCISCPCCaintainer'>
    <div className={isWXCISCMobile ? "AppWXCISX" : "AppWXCISCPC"}><WX4TestQAPage /></div>
  </div>

  // const wx4testmine = <div className="App"><WX4TestMinePage /></div>
  const wx4testmine = <div className={isWXCISCMobile ? "App" : "AppWXCISCPCCaintainer"}>
    <div className={isWXCISCMobile ? "AppWXCISX" : "AppWXCISCPC"}><WX4TestMinePage /></div>
  </div>

  // const wx4testhistory = <div className="App"><WX4TestHistoryPage /></div>
  const wx4testhistory = <div className='AppWXCISCPCCaintainer'>
    <div className={isWXCISCMobile ? "AppWXCISX" : "AppWXCISCPC"}><WX4TestHistoryPage /></div>
  </div>

  // const wx4testReport = <div className="App"><WX4TestReportPage /></div>
  // const wx4testReport = <div className='AppWXCISXReportCaintainer'>
  //   <div className={isWXCISCMobile ? "AppWXCISX" : "AppWXCISCPCReport"}><WX4TestReportPage /></div>
  // </div>
  let wx4testReport = <div className="App"><WX4TestReportPage /></div>;
  if (isWXCISCMobile) {
    wx4testReport = <div className="App"><WX4TestReportPage /></div>
  } else {
    wx4testReport = <div className="AppWXCISXReportCaintainer">
      <div className="AppWXCISCPCReport"><WX4TestReportPage /></div>
    </div>
  }


  const router = createBrowserRouter([
    {
      path: "/",
      element: mainpage,
    },
    {
      path: "/wxcontactus",
      element: wxcontact,
    },
    {
      path: "/ciscqatest",
      element: cisctestQA,
    },
    {
      path: "/ciscqatestreport",
      element: cisctestReport,
    },
    {
      path: "/assessment",
      element: cisctestmine,
    },
    {
      path: "/ciscqatesthistory",
      element: cisctesthistory,
    },
    {
      path: "/fourtestqa",
      element: wx4testQA,
    },
    {
      path: "/assessmentfourtest",
      element: wx4testmine,
    },
    {
      path: "/fourtestreport",
      element: wx4testReport,
    },
    {
      path: "/fourtesthistory",
      element: wx4testhistory,
    },
  ]);


  return (
    <RouterProvider router={router} />
  );
})

export default App;
