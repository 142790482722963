const U = {};

const initWXCISCPageStyle = () => {
  var docEl = document.documentElement;
  docEl.onlyMobileType = true;

  var clientWidth = docEl.clientWidth;
  var clientHeight = docEl.clientHeight;
  // docEl.style.fontSize = 1 * (clientHeight /2 / 750) + 'px';

  if (clientWidth > 0 && clientHeight / clientWidth > 1.65 ) {
    docEl.style.fontSize = 1 * (clientWidth / 750) + 'px';
  } else {
    docEl.style.fontSize = 1 * (clientHeight /2.16 / 750) + 'px';
  }

}

U.initWXCISCPageStyle = initWXCISCPageStyle;


export default U;