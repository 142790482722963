
import React, { useState, useEffect } from "react";
import './style.css'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import RootStore, { setPageState } from "../Store/RootStore";
import ContactUsButton from '../View/ContactUsButton'
import Dialog from '@mui/material/Dialog';
import ContactFromView from '../View/ContactFormView'

export default function SupoortPage(handelTabChange) {

    const [open, setOpen] = React.useState(false);

    const imgsHeight = RootStore.clientWidth * 2263 / 4832;

    const imgs = [
        'https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/viewservice/11-new.png',
        'https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/viewservice/22-new.png',
        'https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/viewservice/33.png',
        'https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/viewservice/44-new.png',
        'https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/viewservice/55.jpg',
        'https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/viewservice/66.png',
    ];

    const contackClick = (event) => {
        setOpen(true);
    };

    return <div className="SupoortPage" >
        <div className="divImageBack">
            {imgs.map((item) => (
                <div className="backimgonediv" style={{ height: imgsHeight }}>
                    <img src={item} className="backimgone" style={{ height: imgsHeight }} />
                </div>
                // <div className="backimgonediv" style={{ height: window.innerHeight }}>
                //     <img src={item} className="backimgone" style={{ height: window.innerHeight }} />
                // </div>
            ))}
        </div>
        <div className="contentText">
            <div className="textCN text1" >您将会通过<span className="textClickCN" onClick={contackClick}>CISC的微信公众号</span>进行一项专业的心理健康评估。这项评估是由我们中心的心理健康专家研发，它将对您最近的心理和情绪健康状态进行非正式评估。</div>
            <div className="textEN text2" >You will receive a free emotional well-being assessment via <span className="textClickEN" onClick={contackClick}>WeChat</span> The assessment was designed and developed by the mental health professionals at the centre. It will provide you with an informal assessment of your recent mental and emotional health states.
            </div>
            <div className="textCN text3" >您将在线或在伦敦的支持中心与我们的心理健康支持团队进行一对一的普通话交流。如果您正面临焦虑、孤独感或抑郁等问题，我们可以提供具体实际的支持。我们将一起讨论您的目标和期望，以及如何协助您实现它们。</div>
            <div className="textEN text4" >You will speak to our team of emotional wellness supporters on a one-to-one basis online or at our centre in London in Mandarin. We can provide practical support if you are struggling with anxiety, a sense of isolation or depression. We’ll talk to you about your goals and aspirations and how we can assist you in achieving them.
            </div>
            <div className="textCN text5" >我们将为您提供如何顺利度过文化适应期各方面信息和知识，从而尽快地适应新的环境，投入到崭新的海外人生阶段。</div>
            <div className="textEN text6" >We could provide comprehensive information about what organisations, services and activities are available in your community, as well as information and tools to help you during the cultural adaptation process.
            </div>
            <div className="textCN text7" >中心将定期举办各类型有关情绪健康与个人成长的课程与工作坊。从这些课程中您不仅可以获得相关知识，改善自身的身体与情绪健康，也可以帮助了解自我，从而获得长足的个人生命成长。</div>
            <div className="textEN text8" >We offer a programme of workshops that provide information on mental health and personal development. You will be able to learn and develop critical skills to improve physical and mental health. The programme also aims to assist you in better understanding yourself and achieving long-lasting positive changes in life.</div>
            {ViewHowToContactUS(contackClick)}
            {ViewSecondBottom()}
        </div>
        <div className='rightView'>
            {ContactUsButton(true, () => { setOpen(true) })}
        </div>
        <Dialog open={open} maxWidth={'100%'} fullWidth={false} fullScreen={false} onClose={() => {
            setOpen(false);
        }}
            PaperProps={{ sx: { backgroundColor: 'transparent', borderRadius: '21px' } }}
        >
            {/* <ContactFromView fromDialog={true} /> */}
            {ContactFromView(true)}
        </Dialog>
    </div>;

}

function ViewHowToContactUS(contackClick) {
    return (
        <>
            <div className="textCN2 text9">如果您是中国海外留学生，目前在学习、生活或个人发展方面遇到一些挑战，同时一些情绪方面的问题令您无法有效地面对这些挑战，不用过分担心，请尽快<span className="textClickCN" onClick={contackClick}>与我们取得联系</span>。我们一般会在48小时内回复您，并根据您的情况和具体需要，提供最合适您的心理支持服务。</div>
            <div className="textEN2 text10">If you are experiencing a difficult time in your academic or personal life and your emotional health is preventing you from overcoming these difficulties, please do not hesitate to <span className="textClickEN" onClick={contackClick}>reach out to CISC</span>. We aim to respond to you within 48 hours and provide the most suitable well-being support services based on your specific needs.</div>
        </>
    );
}

function ViewSecondBottom() {

    const ppclick = (event) => {
        setPageState('5');
        // 将页面滚动到顶部
        window.scrollTo(0, 0);
    };

    const email = 'support@ciscic.org.uk'
    const now = new Date();
    const currentYear = now.getFullYear();

    return (
        <>
            <div className="secondbottom">
                <div className="textBottom textbottom1">中国海外留学生心理支持中心 CISC</div>
                <div className="textBottom textbottom2">Chinese International Students Wellbeing Support Centre</div>
                <div className="textBottom textbottom3">Email Address:<a className="email" href={`mailto:${email}`}>{email}</a></div>
                <div className="textBottom textbottom4">Address: 8 Northumberland Avenue, London, WC2N 5BY, United Kingdom</div>
                <div className="bottomLine"></div>
                <div className="footerSection">
                    <div className="footerText2 foothover" onClick={ppclick}>Privacy Policy</div>
                    <div className="seperate"> | </div>
                    <div className="footerText2">
                        Copyright © 2019 - {currentYear} Chinese International Students Well-being Support Centre CIC (Company no.12249552) - All Rights Reserved.
                    </div>
                </div>
                <div className="viewQRCode">
                    <div className="viewdivImage">
                        <img className="imgQRCode" src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/qrcode.png" />
                    </div>
                    <div className="textQRCode">CISC微信公众号</div>
                </div>
            </div>

        </>
    );
}