// Photos from https://citizenofnowhe.re/lines-of-the-city
import React, { useEffect, useState, Component } from "react";
import "./styles.css";
import { APIPost, APIPostForm } from "../../API/APIMethod";
import { useNavigate } from "react-router-dom";
import Utils from '../../Utils/Utils'



function WX4TestReportPage() {

  let navigate = useNavigate();

  const [data, setData] = useState({});
  const [showTop, setShowTop] = useState(false);
  const [fourType, setFourType] = useState('typeC');
  const [fourSecondType, setFourSecondType] = useState('typeNone');


  useEffect(() => {
    console.log('componentDidMount');

      // 设置字体
    Utils.initWXCISCPageStyle();

    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');
    // const queryParams = new URLSearchParams(location.search);
    // const id = queryParams.get('id');
    console.log('report id', id);

    requestReport({ id: id })

    console.log('testreport wxopenid', global.wxOpenId)
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      // console.log('Page scrolled, scrollTop: ', scrollTop);
      if (scrollTop > 80) {
        setShowTop(true)
      } else {
        setShowTop(false)
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const requestReport = (params) => {
    // const path = '/pay/public/createCheckoutSession';
    const path = '/getciscusertypefourtestanswerbyid/';
    const onSuccess = (res) => {
      const data = res?.data;
      console.log('data', data);
      if (data) {
        setData(data.cisctypefouranswerinfo)
        setFourType(data.cisctypefouranswerinfo.fourType)
        if (data.cisctypefouranswerinfo.fourSecondType === 'typeC' && parseInt(data.cisctypefouranswerinfo.typeCScore) >= 5) {
          setFourSecondType(data.cisctypefouranswerinfo.fourSecondType)
        } else if (data.cisctypefouranswerinfo.fourSecondType === 'typeP' && parseInt(data.cisctypefouranswerinfo.typePScore) >= 5) {
          setFourSecondType(data.cisctypefouranswerinfo.fourSecondType)
        } else if (data.cisctypefouranswerinfo.fourSecondType === 'typeS' && parseInt(data.cisctypefouranswerinfo.typeSScore) >= 5) {
          setFourSecondType(data.cisctypefouranswerinfo.fourSecondType)
        } else if (data.cisctypefouranswerinfo.fourSecondType === 'typeM' && parseInt(data.cisctypefouranswerinfo.typeMScore) >= 5) {
          setFourSecondType(data.cisctypefouranswerinfo.fourSecondType)
        }
      }
    }
    APIPost(path, params)
      .then(onSuccess)
  }

  const anxietyScore = parseInt(data?.anxietyScore);

  console.log('xxxx', anxietyScore);


  const convertTime = (originalDateTimeString) => {
    const date = new Date(originalDateTimeString);
    const formattedDateTime = `${date.getFullYear()}年${(date.getMonth() + 1).toString().padStart(2, '0')}月${date.getDate().toString().padStart(2, '0')}日 ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;

    return formattedDateTime;
  }

  const handleGotoMain = () => {
    console.log('handleGotoMain');
    navigate('/fourtesthistory');
  }

  const handleGotoCiscTest = () => {
    navigate('/assessment');
  }

  const handleGotoContact = () => {
    navigate('/wxcontactus');
  }


  const text1 = '求学路上，我们会遇到各式各样的人。正如世界上没有两片完全相同的树叶一样，也没有完全相同的两个人。人与人之间存在着身形容貌的不同，也存在着性格脾气的差异。因此，在学习和生活中，你实际上是在跟不同性格类型的人在打交道，俗话说“性格决定命运”，唯有知己知彼，才能化繁为简地处理好人际关系。';
  const text3 = '心理学对人的性格有很多的分类研究，最早且常见的是把人分成四种性格类型（4P 性格类型），也恰恰是中国古典名著《西游记》里唐僧师徒四人的生动写照。'
  const textC5 = '力量型的人是天生的行动者和领导者。你们积极主动，果断、自信、讲效率，执行力超强。一旦确定目标就要付诸行动，勇往直前。同时，你们说话的方式比较直接，更在意表达自己，不太在意别人的感受，并且喜欢争论和挑战，爱面子，讲对错，好为人师，永不服输！';
  const textC7 = '孙悟空是西天取经团队的主力干将，他神通广大，变化多端；充满活力，目标明确；性格外向，积极乐观；但是他常常以自我为中心，恃才自傲，犯上作乱，不愿受团队的约束，也不愿接受唐僧的管理。很明显，没有孙悟空，西天取经的远大目标恐难以实现，但他极其鲜明的“逆反”个性，也给团队增加了不少压力和“麻烦”。'
  const textC9 = '赢得夸奖时要学会谦虚，说：没什么，这是我应该做的。做错了事情要学会认怂，说：抱歉，对不起！看见别人做的好，要学会赞美，说：好厉害，向你学习。发现别人有些慢，要耐心等候，说：没事儿，不着急~'
  const textS5 = '活泼型的人非常爱说话，他们喜欢以自我为中心，总能眉飞色舞、滔滔不绝地发言，你要做的就是给予他足够的关注，跟他一起热闹一会儿，或者安静地做个听众，以点头或者微笑的眼神示意你的反馈就可以了。';
  const textS7 = '猪八戒呢，是典型的活泼型。他性格开朗，说话随意，总是在发表自己的想法，似乎好吃懒做，贪财好色，只是个笑料角色，但他能够接受大家的批评，也能容忍小小的委屈，承担了团队减压器的功能，发挥了团队润滑剂的作用。试想一下，如果没有猪八戒这个好玩儿的人物，团队在漫长的取经“征途”中该有多么沉闷，连读者可能都看不下去。'
  const textS9 = ''
  const textM5 = '完美型的人善于思考，富有哲理，凡事追求尽善尽美。他们喜欢做计划，在乎细节和条理；他们守时，讲原则，善于发现问题，有一双挑剔的眼睛。你要做的就是谨言慎行，说话时尽量用专业词汇，要有逻辑，拿出数据，不要含糊其辞，更不要夸夸其谈。';
  const textM7 = '唐僧呢，是最最典型的完美型。他性格内向，思维缜密，对自己和团队的其他成员都高标准、严要求，行为举止不得越“雷池”一步。他是个百分百的理想主义者，富有大无畏的自我牺牲的精神，具有高度的责任心和忍耐力，但却常常让人觉得不食人间烟火，固执得不通情理。假如他穿越到了现代职场，也许他的工作能力有些力不从心，但也同样非常适合做小团队的领导（比如部门经理），因为他从古至今都不是靠能力吃饭的，以德服人——才是他立身处世的最终追求。'
  const textM9 = ''
  const textP5 = '平和型的人是和蔼可亲的好好先生，似乎与世无争，内向保守，温和安静，为人低调。他们奉行多一事不如少一事，喜欢轻松，害怕压力，不愿得罪人。说话时不是少言少语，就是绕来绕去，难以表达自己真实的想法。你要做的就是多说话，也要想办法（提问）引导他多说话，慢慢弄清他的真实想法。一旦你能够跟他“交心”，你就成了他的永远的朋友。';
  const textP7 = '沙和尚是典型的和平型。他少言寡语却心中有数，一路上任劳任怨，从不挑肥拣瘦，承担了挑担子这种劳苦而无聊的工作。从个人能力上讲，沙和尚是战斗力最弱，最不懂变通的人，但他承担了没人愿意承担的工作，使悟空能够腾出手脚，天马行空，降魔除妖。在唐僧受难，悟空被逐，八戒嚷嚷散伙的时候，是沙和尚以平和的心态，理智地劝解，使团队没有一哄而散。看似平和的沙和尚，在关键时刻，成为了团队的稳定器。'
  const textP9 = ''

  let text5, text7, text9;
  if (fourType === 'typeC') {
    text5 = textC5;
    text7 = textC7;
    text9 = textC9;
  } else if (fourType === 'typeS') {
    text5 = textS5;
    text7 = textS7;
    text9 = textS9;
  } else if (fourType === 'typeM') {
    text5 = textM5;
    text7 = textM7;
    text9 = textM9;
  } else if (fourType === 'typeP') {
    text5 = textP5;
    text7 = textP7;
    text9 = textP9;
  } 



  const textC11 = '据统计，像孙悟空那样典型且纯粹的力量型只有 5%左右，大多数人都是复合型的性格类型，根据你的问卷，可以发现你的第二性格特质是：'
  const textC13 = '也就是说，你同时具有孙悟空和猪八戒的双重特质，这样是好还是不好呢？'

  // 待补充
  let text11, text13;

  if (fourSecondType === 'typeC') {
    text11 = textC11;
    text13 = textC13;
  }


  const fixedElement = document.querySelector('.navitop');
  const parentWidth = document.querySelector('.viewWX4TestReportPage')?.offsetWidth;
  if (fixedElement && parentWidth) {
    fixedElement.style.width = parentWidth + 'px';
  }


  return (
    <div className="viewWX4TestReportPage">
      <div className="left" onClick={handleGotoMain}>
        <img className="imageBack" src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/phychotest/history/back.png"></img>
      </div>
      {1 && (
        <div className={`navitop ${showTop ? 'visible' : ''}`}>
          <div className="viewtopBack" onClick={handleGotoMain}>
            <img className="imageBack" src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/phychotest/history/back.png"></img>
          </div>
          <div className="textnavitop">您的检查报告详情</div>
        </div>)
      }
      <div className="bk" >
        <img className="imgbk" src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/fourtypetest/report/reportbk.png" />
      </div>
      <div className="viewqy">
        <img className="imgqy" src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/fourtypetest/report/reportqianyan.png" />
      </div>
      <div className="view4pshuoming"></div>
      <div className="reportwukong"></div>
      <div className="reportwukongshuoming"></div>
      <div className="reportwukongtips"></div>
      <div className="reportsecondbajie"></div>
      <div className="reportsecondtips"></div>
      <div className="reportlink">
        <div className="viewlinkcisctest" onClick={handleGotoCiscTest}></div>
        <div className="viewlinkcontact" onClick={handleGotoContact}></div>
      </div>

      <div className="viewDate">
        <img className="imgDate" src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/phychotest/report/reportdate.png" />
        <div className="textDate">{convertTime(data?.createtime)}</div>
      </div>
      <div className="viewFooter">
        <img src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/phychotest/footer.png" />
      </div>
      {/* <div className="textQuestion" >{convertTime(data?.createtime)}</div> */}
    </div>
  );
}

export default WX4TestReportPage;