import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { APIPost } from "../../API/APIMethod";
import { Box, TextField, Typography, MenuItem } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Cookies from 'js-cookie';
import "./styles.css";
import Utils from '../../Utils/Utils'


const theme = createTheme({
  palette: {
    primary: {
      main: '#D0D3D5', // 设置主要颜色
    },
    secondary: {
      main: '#D05EE1', // 设置次要颜色
    },
    error: {
      main: '#DD0000', // 设置次要颜色
    },
    // 更多颜色定义...
  },
});



function WX4TestMinePage() {
  const [resultList, setResultList] = useState([]);
  const [showList, setShowList] = useState(true);
  const [errorEmail, setErrorEmail] = useState(false)
  const [name, setName] = useState('');

  const checkEmail = () => {
    const reg = new RegExp(
      '^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$',
    );
    setErrorEmail(!(global.wxOpenId?.match(reg)))
    return global.wxOpenId?.match(reg);
  };


  let navigate = useNavigate();

  useEffect(() => {
    console.log('componentDidMount');
    // 设置字体
    Utils.initWXCISCPageStyle();
    
    document.title = 'CISC心检';
    // Cookies.remove('ciscTestwxOpenId');
    const dataFromCookie = Cookies.get('ciscTestwxOpenId');
    if (dataFromCookie) {
      global.wxOpenId = dataFromCookie;
    }

    if (global.wxOpenId) {
      requestData({ openid: global.wxOpenId });
    } else {
      setShowList(false)
    }
  }, []);

  const requestData = (params) => {
    const path = '/getciscusertypefourtestanswerlistbyopenid/';
    const onSuccess = (res) => {
      const data = res?.data;
      console.log('data', data);
      if (data.cisctypefouranswerinfolist.length >= 0) {
        setResultList(data.cisctypefouranswerinfolist);
        setShowList(true);
      }
    };
    APIPost(path, params).then(onSuccess);
  };

  const handleClick = (item) => {
    const queryParams = new URLSearchParams();
    queryParams.append('id', item.id);

    const path = {
      pathname: '/fourtestreport',
      search: queryParams.toString()
    };

    console.log('reportxxx', path);
    return path;
  };

  const handleClickLinkToTestPage = () => {
    const queryParams = new URLSearchParams();
    const path = {
      pathname: '/fourtestqa',
      search: queryParams.toString()
    };
    console.log('reportxxx', path);
    return path;
  };

  const handleGotoTestPage = () => {
    navigate('/fourtestqa');
  };

  const handleGotoHistoryPage = () => {
    navigate('/fourtesthistory')
  }

  const handleChange = (event) => {
    setName(event.target.value)
    global.wxOpenId = event.target.value;
  };

  const handleSubmitEmail = () => {
    console.log('handleSubmitEmail');
    if (checkEmail()) {
      Cookies.set('ciscTestwxOpenId', global.wxOpenId, { expires: 1 }); // 设置cookie过期时间为7天
      requestData({ openid: global.wxOpenId });      
    }
  };

  if (!showList) {
    return (
      <div className="viewLogin">
        <div className="viewTop">
          <img className="imagebk" src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/fourtypetest/loginback.png" alt="Example Image" /> 
        </div>
        <div className="viewBottom">
          <div className="textTitle">邮箱</div>
          <Box
            sx={{
              // width: '654rem',
              height: 50,
              marginTop: '20rem',
              marginBottom: '40rem',
              width: '654rem',
            }}
          >
            <ThemeProvider theme={theme}>
              <TextField className='nameTextField' fullWidth id="email"
                value={name}
                onChange={handleChange}
                color={'primary'}
                maxRows={1}
                type="email"
                name="email"
                label={
                  <Typography variant="body1" fontSize={'18px'} align={'center'} mt={'-3.0px'} color={errorEmail ? '#DD0000' : '#8F959E'} >
                    请输入正确的邮箱
                  </Typography>
                }
                error={errorEmail}
                // helperText={errorEmail && 'Please enter a valid email address.'}
                onBlur={() => {
                  setErrorEmail(!checkEmail())
                }}
                FormHelperTextProps={{ sx: { fontSize: '10px' } }}
                InputLabelProps={{ fontSize: '16px' }}
                InputProps={{ sx: { fontSize: '16px', height: '45px', backgroundColor: 'trasparent', borderRadius: '20rem' } }}
                variant="standard"
              />
            </ThemeProvider>
          </Box>
          <div className="textTips" >请正确输入您的电子邮箱地址，以便查看您的心检报告</div>
          <div className="viewSubmit" onClick={handleSubmitEmail}>
            <img className="imagesubmit" src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/fourtypetest/create.png" />
          </div>
          <div className="viewfooter">
            <img className="imagefooter" src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/phychotest/footer.png" />
          </div> 
        </div>
      </div>
    );
  }

  return (
    <div className="viewFourTestMinePage">
      <img className="bktop" src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/fourtypetest/mainback.png"></img>
      <div className="viewtop">
        <div><img className="imageIcon" src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/phychotest/main/icon.png"></img></div>
        <div className="textHistory" onClick={handleGotoHistoryPage}>历史记录</div>
      </div>
      <div className="textQuestion">4P 性格类型测量表</div>
      <div className="viewimageMid">
        <img className="imageMid" src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/phychotest/main/middle.png" />
      </div>
      <div className="viewStart" onClick={handleGotoTestPage}>
        <img className="imagestart" src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/fourtypetest/mainstart.png" />
      </div>
      <div className="viewfooter">
        <img className="imagefooter" src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/fourtypetest/mainbottomlogo.png" />
      </div> 
      {/* {resultList.map((item) => (
        <div className="textResult">
          <Link to={handleClick(item)} className="textResult">{item.createtime}</Link>
        </div>
      ))} */}
      {/* <div onClick={handleGotoTestPage} className="textStart">开始测试</div> */}
      {/* <div><Link to={handleClickLinkToTestPage()} className="textStart">开始测试</Link></div> */}
    </div>
  );
}

export default WX4TestMinePage;